import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDimensions = props => {
  const { heading, value } = props;
  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {heading}
      </Heading>
      <div className={'grid xs:grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-6 pl-[17px]'}>
        <div className={'flex'}>
          <div className={'min-w-[4rem] font-medium p-0 m-0 mr-4'}>Width:</div>
          {value?.width} kg
        </div>
        <div className={'flex'}>
          <div className={'min-w-[4rem] font-medium p-0 m-0 mr-4'}>Height:</div>
          {value?.height} cm
        </div>
        <div className={'flex'}>
          <div className={'min-w-[4rem] font-medium p-0 m-0 mr-4'}>Depth:</div>
          {value?.depth} cm
        </div>
        <div className={'flex'}>
          <div className={'min-w-[4rem] font-medium p-0 m-0 mr-4'}>Weight:</div>
          {value?.weight} kg
        </div>
      </div>
    </div>
  );
};

export default SectionDimensions;
