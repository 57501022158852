import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
  SCHEMA_TYPE_DIMENSIONS,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Button,
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  Heading,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  sendBid,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
  handleSubmitBid,
  handleSubmitInstantBid,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionDimensions from './SectionDimensions';
import SectionGallery from './SectionGallery';
import SendMessageForm from '../../containers/TransactionPage/SendMessageForm/SendMessageForm';
import DetailCardImage from '../../containers/TransactionPage/TransactionPanel/DetailCardImage';

import css from './ListingPage.module.css';
import BidModal from './BidModal/BidModal';
import { queryStringToJSON } from './utils';
import { useEffect } from 'react';
import AuthModal from '../../components/AuthModal/AuthModal';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const queryStringValues = queryStringToJSON(props?.location?.search);
  const isOffer = queryStringValues?.offer;
  const quantity = queryStringValues?.quantity && Number(queryStringValues?.quantity);
  const isInquiry = queryStringValues?.inquiry;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = props.listingConfig || props.config.listing;
  const listingId = new UUID(props.params.id);
  const isPendingApprovalVariant = props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = props.params.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(props.getOwnListing(listingId))
      : ensureListing(props.getListing(listingId));

  const [userBidPrice, setUserBidPrice] = useState('');
  const [percentageDifference, setPercentageDifference] = useState('');

  const originalPrice = currentListing?.attributes?.price?.amount / 100;

  const sellerDisplayName = currentListing?.author?.attributes?.profile?.displayName;

  // Handler for bid price input change
  const handleBidPriceChange = (e) => {
    const newBidPrice = e.target.value;
    setUserBidPrice(newBidPrice); // Set the new bid price

    // Only calculate and set percentage difference if the new bid price is valid
    if (newBidPrice) {
      const difference = ((originalPrice - newBidPrice) / originalPrice) * 100;
      setPercentageDifference(difference.toFixed(2));
    } else {
      // If the bid price input is cleared, also clear the percentage difference
      setPercentageDifference('');
    }
  };

  // Handler for percentage input change
  const handlePercentageChange = (e) => {
    const newPercentage = e.target.value;
    setPercentageDifference(newPercentage); // Set the new percentage

    // Only calculate and set bid price if the new percentage is valid
    if (newPercentage) {
      const calculatedBidPrice = originalPrice - (originalPrice * (newPercentage / 100));
      setUserBidPrice(calculatedBidPrice.toFixed(2));
    } else {
      // If the percentage input is cleared, also clear the bid price
      setUserBidPrice('');
    }
  };

  const handleBidSubmit = () => {
    handleSubmitInstantBid({
      history: props.history,
      params: props.params,
      routes: props.routeConfiguration,
      bidPrice: Number(userBidPrice) * 100,
      getListing: props.getListing,
      onSendBid: props.onSendBid,
      currentUser: props.currentUser,
      setAuthModalOpen: setAuthModalOpen,
      setBidModalOpen: () => {},
      quantity,
      listing: currentListing,
    });
  };

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onSendBid,
  } = props;

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    currentUser,
    setAuthModalOpen,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const onSubmitBid = handleSubmitBid({
    ...commonParams,
    getListing,
    onSendBid,
    setBidModalOpen,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
  const titleText = isInquiry ? 'Make an Inquiry' : 'Make an Offer';

  console.log(currentListing);
  if (isOffer || isInquiry) {
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          offers: {
            '@type': 'Offer',
            url: productURL,
            ...schemaPriceMaybe,
            availability: schemaAvailability,
          },
        }}
      >      
        <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
          <AuthModal isOpen={authModalOpen} setOpen={setAuthModalOpen} />
          <div className={css.root}>
            <div className="max-w-[calc(1056px+72px)] px-6 md:mx-auto w-full mb-16 md:mb-24">
              <div className={`${css['title-left']} text-[30px] font-[400] text-bazaa mt-8 md:mt-24`}>
                <span>{titleText}</span>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-5 mt-2 gap-12 md:gap-16">
                <div className="md:col-span-2 border-2 border-fawn px-6 py-6 rounded-md border-solid h-fit">
                  <DetailCardImage
                    rootClassName=""
                    avatarWrapperClassName=""
                    listingTitle={currentListing?.attributes?.title}
                    image={currentListing?.images?.length > 0 ? currentListing?.images[0] : null}
                    listingImageConfig={config?.layout?.listingImage}
                  />
                  <p className={css.p}>{currentListing?.attributes?.title}</p>
                  {isOffer ? (
                    <p className={css.p}>Quantity: {quantity}</p>
                  ) : null}
                </div>
                {isOffer ? (
                  <div className="md:col-span-3 flex flex-col gap-12">
                    <div>
                      <h4 className={css.h4}>Send an offer to { sellerDisplayName ? sellerDisplayName : "the seller"} </h4>
                      <p className={css.p}>Asking price per item: ${originalPrice ? originalPrice.toFixed(2) : "Error - Please refresh the page" } </p>
                    </div>
                    <div>
                      <p className={css.p}>Enter your offer (per item for your selected quantity)</p>
                      <div className={css.bidSection} style={{ display: "flex" }}>
                        <span style={{
                          width: "8%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #d8dce6",
                          borderRight: "0px",
                        }}>$</span>
                        <input
                          type="number"
                          value={userBidPrice}
                          onChange={handleBidPriceChange}
                          className={css.bidInput} // Assign appropriate CSS class for styling
                          style={{ borderRadius: '0px', borderLeft: "0px" }}
                        />
                        <input
                          type="number"
                          value={percentageDifference}
                          onChange={handlePercentageChange}
                          className={css.percentageInput}
                        />
                        <span style={{
                          width: "10%",
                          justifyContent: "left",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #d8dce6",
                          borderLeft: "0px",
                          backgroundColor: "white"
                        }}>% off</span>
                      </div>
                      <p className={css.p}>Total to pay if offer accepted:{' '}
                        <strong>
                          {quantity} x ${userBidPrice ? Number(userBidPrice).toFixed(2) : " Your Offer"} = ${userBidPrice ? (Number(quantity) * Number(userBidPrice)).toFixed(2) : " Total Price"}
                        </strong>
                      </p>
                      <p>
                      Shipping will be calculated in the next step
                      </p>
                    </div>
                    <div>
                      <Button
                        onClick={handleBidSubmit}
                        type="button"
                        className="rounded-lg transition disabled:bg-fawn bg-light-bazaa px-6 py-3 border-2 disabled:border-[#27475166] border-light-bazaa text-[14px] disabled:text-dark-fawn text-white flex-1"
                      >
                        REVIEW OFFER
                      </Button>
                    </div>
                  </div>
                  ) : null }
                  {isInquiry ? (
                    <div className="md:col-span-3 flex flex-col gap-12">
                      <div>
                        <h4 className={css.h4}>Send { sellerDisplayName ? sellerDisplayName : "the seller"} a message</h4>                      
                      </div>
                      <SendMessageForm
                        inProgress={sendInquiryInProgress}
                        sendMessageError={sendInquiryError}
                        onSubmit={onSubmitInquiry}
                        messagePlaceholder="Add your comment or question"
                      />
                  </div>                    
                  ) : null}
              </div>
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  } else {
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          offers: {
            '@type': 'Offer',
            url: productURL,
            ...schemaPriceMaybe,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
          <BidModal
            onSubmit={onSubmitBid}
            inProgress={sendInquiryInProgress}
            modalOpen={bidModalOpen}
            setModalOpen={setBidModalOpen}
            listing={currentListing}
          />
          <div className={css.contentWrapperForProductLayout}>
            <div className={css.mainColumnForProductLayout}>
              {currentListing.id ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingPathParamType,
                    tab: listingTab,
                  }}
                />
              ) : null}
              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.listingImage.variantPrefix}
              />
              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>
              <SectionTextMaybe text={description} showAsIngress />
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                intl={intl}
              />
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
                const hasValue = value !== null;
                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                      ...pickedElements,
                      <SectionMultiEnumMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        options={createFilterOptions(enumOptions)}
                        selectedOptions={value}
                      />,
                    ]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                      ...pickedElements,
                      <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                    ]
                  : hasValue && config.schemaType === SCHEMA_TYPE_DIMENSIONS
                  ? [
                      ...pickedElements,
                      <SectionDimensions
                        key={key}
                        heading={config?.showConfig?.label}
                        value={value}
                      />,
                    ]
                  : pickedElements;
              }, [])}
  
              {/*<SectionMapMaybe*/}
              {/*  geolocation={geolocation}*/}
              {/*  publicData={publicData}*/}
              {/*  listingId={currentListing.id}*/}
              {/*  mapsConfig={config.maps}*/}
              {/*/>*/}
            </div>
            <div className={css.orderColumnForProductLayout}>
              <OrderPanel
                className={css.productOrderPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                setBidModalOpen={setBidModalOpen}
              />
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onSendBid: (listing, bidPrice) => dispatch(sendBid(listing, bidPrice)),
});


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
