import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, FieldTextInput, NamedLink, Button } from '../../../components';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const signupLink = (
        <NamedLink name="SignupPage" className="underline text-bazaa-light">
          <FormattedMessage id="LoginForm.signup" />
        </NamedLink>
      );

      return (
        <Form onSubmit={handleSubmit}>
          <div className="mt-4">
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className="mt-6"
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="current-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordRequired}
            />
          </div>
          <div className="grid grid-cols-2 gap-2 mt-10">
            <Button
              type="submit"
              disabled={submitDisabled}
              inProgress={submitInProgress}
              className="rounded-xl transition disabled:bg-fawn bg-light-bazaa px-6 py-3 border-2 disabled:border-[#27475166] border-light-bazaa text-lg font-bold disabled:text-dark-fawn text-white flex-1"
            >
              <FormattedMessage id="LoginForm.logIn" />
            </Button>
            <NamedLink name="PasswordRecoveryPage" className="flex-1 font-bold flex items-center justify-center underline text-lg text-bazaa-light">
              <FormattedMessage id="LoginForm.forgotPassword" />
            </NamedLink>
          </div>
          {props.showSignup && (
            <p className="font-bold text-lg text-center mx-auto mt-12">
              <FormattedMessage
                id="LoginForm.signupPrompt"
                values={{ signupLink }}
              />
            </p>
          )}
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  showSignup: true,
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  showSignup: bool,
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
