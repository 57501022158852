import React from 'react';
import css from './AuthModal.module.css';
import Modal from '../Modal/Modal';
import SignupForm from '../../containers/AuthenticationPage/SignupForm/SignupForm';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { authenticationInProgress, login, signup, signupWithIdp } from '../../ducks/auth.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { useState } from 'react';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';

const AuthModalComponent = props => {
  const [tab, setTab] = useState('login');
  const {
    isOpen,
    setOpen,
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    from,
    submitLogin,
    loginError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
  } = props;

  const handleSubmitSignup = async values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    await submitSignup(params);
    setOpen(false);
  };

  const handleLogin = async args => {
    await submitLogin(args);
    setOpen(false);
  };

  const loginErrorMessage = (
    <div className="mb-4">
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className="mb-4">
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      onManageDisableScrolling={() => { }}
      containerClassName="bg-light-fawn"
    >
      <div className="max-w-sm w-full mx-auto">
        <div className="relative flex flex-col">
          <h1 className="text-3xl md:text-5xl text-bazaa mx-auto text-teal font-moret mt-4 mb-8">
            {tab === 'login' ? 'Login to your account' : 'Sign Up'}
          </h1>
          {loginOrSignupError}
          {tab === 'login' ? (
            <>
              <LoginForm
                showSignup={false}
                onSubmit={handleLogin}
                inProgress={authInProgress}
              />
              <span className="font-bold text-lg text-center mx-auto mt-12">
                Don't have an account?
                <button onClick={() => setTab('signup')} className="text-bazaa underline border-none cursor-pointer">
                  Sign up
                </button>
              </span>
            </>
          ) : (
            <>
              <SignupForm
                showLogin={false}
                onSubmit={handleSubmitSignup}
                inProgress={authInProgress}
                termsAndConditions={termsAndConditions}
              />
              <span className="font-bold text-lg text-center mx-auto mt-12">
                Already have an account?
                <button onClick={() => setTab('login')} className="text-bazaa underline border-none cursor-pointer">
                  Login
                </button>
              </span>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const AuthModal = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthModalComponent);

export default AuthModal;
